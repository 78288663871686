import './src/layout/global.scss'
import "@fortawesome/fontawesome-svg-core/styles.css"

// props の locationは必ず書く
export const onRouteUpdate = ({ location }) => { 
  const div = document.querySelector("#dad");
  if (div) {
    const span = document.createElement("span");
    span.textContent = "当サイトでは一部プロモーションが含まれることがあります";
    div.appendChild(span);
  }
  
}
